import { Image } from "@fluentui/react-components";
import "./NoSubScription.css";

const NoSubscription = () => {
  return (
    <div className="no-sub-container">
      <div className="no-sub-header">
        <Image src="logo-white.svg" />
      </div>
      <div className="no-sub-content">
        <div className="center">
          {" "}
          <Image src="open-mail-green.png" />
          <h1>
            Welcome to <strong>Zuzo</strong>!
          </h1>
          <p>It doesn't seem like you have been enable for Zuzo yet...</p>
          <p>
            Please reach out to the existing Zuzo Admin within your company to
            get started.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NoSubscription;
