import axios, { AxiosInstance } from "axios";
import envConfig from "./config";
import { RequestConfig } from "../types/api-types";

const baseURL = (envConfig.botApiEndpoint as string) || "";

const axiosBotRequest = (secured = true): AxiosInstance => {
  const config = {
    baseURL,
    // 404 NotFound should NOT throw an error
    validateStatus: (status: number) =>
      (status >= 200 && status < 300) || status === 404,
  } as RequestConfig;

  if (secured) {
    config.headers = {
      "X-API-KEY": envConfig.zuzoToken,
    };
  }

  return axios.create(config);
};

export default axiosBotRequest;
