import React, { useEffect } from "react";
// https://fluentsite.z22.web.core.windows.net/quick-start
import {
  FluentProvider,
  BrandVariants,
  Theme,
  createLightTheme,
  createDarkTheme,
} from "@fluentui/react-components";
import {
  HashRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { app } from "@microsoft/teams-js";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import Tab from "./Tab";
import GiveReward from "./GiveReward";
import TabConfig from "./TabConfig";
import { TeamsFxContext } from "./Context";
import config from "../helpers/config";
import "./App.css";

const zuzoTheme: BrandVariants = {
  10: "#020403",
  20: "#101C15",
  30: "#162E21",
  40: "#193C2A",
  50: "#1C4A33",
  60: "#1F593C",
  70: "#216745",
  80: "#23774F",
  90: "#248659",
  100: "#249663",
  110: "#24A76D",
  120: "#23B778",
  130: "#21C882",
  140: "#1ED98D",
  150: "#19EB98",
  160: "#40FAA7",
};

const lightTheme: Theme = {
  ...createLightTheme(zuzoTheme),
};

const darkTheme: Theme = {
  ...createDarkTheme(zuzoTheme),
};

darkTheme.colorBrandForeground1 = zuzoTheme[110];
darkTheme.colorBrandForeground2 = zuzoTheme[120];
/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const { loading, theme, themeString, teamsUserCredential } =
    useTeamsUserCredential({
      initiateLoginEndpoint: config.initiateLoginEndpoint!,
      clientId: config.clientId!,
    });
  useEffect(() => {
    loading &&
      app.initialize().then(() => {
        // Hide the loading indicator.
        app.notifySuccess();
      });
  }, [loading]);
  const searchParams = new URLSearchParams(window.location.search);
  console.log("searchParams", searchParams.get("context"));

  let context = searchParams.get("context");
  let params: string[] = [];
  if (context) {
    params = context.split("|");
  }
  return (
    <TeamsFxContext.Provider
      value={{ theme, themeString, teamsUserCredential }}
    >
      <FluentProvider
        theme={{
          ...darkTheme,
          fontFamilyBase: "Poppins",
        }}
        style={{ background: "#1a1a1a" }}
      >
        <Router>
          {!loading && (
            <Routes>
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/termsofuse" element={<TermsOfUse />} />
              <Route path="/tab" element={<Tab />} />
              <Route
                path="/give-reward/:id/:nomineeId/:nomineeName/:originalNominatorId"
                element={<GiveReward />}
              />
              <Route path="/config" element={<TabConfig />} />
              <Route
                path="*"
                element={
                  <Navigate
                    to={`/give-reward/${params[0]}/${params[1]}/${params[2]}/${params[3]}`}
                  />
                }
              ></Route>
            </Routes>
          )}
        </Router>
      </FluentProvider>
    </TeamsFxContext.Provider>
  );
}
