import { AxiosResponse } from "axios";
import axiosBotRequest from "../helpers/axios-bot";
import {
  CompanyValuesResponse,
  CreateNomination,
  CreateVote,
  PeerToPeerIssuance,
  SubscriptionStatus,
  TeamMemberResponse,
  TeamsBotCredentialUpdateResponse,
} from "../types/api-types";

export const getTeamMembers = async (
  tenantId: string
): Promise<AxiosResponse<TeamMemberResponse>> =>
  axiosBotRequest().get(`/api/members?tenantId=${tenantId}`);

export const getCompanyValues = async (
  tenantId: string
): Promise<AxiosResponse<CompanyValuesResponse>> =>
  axiosBotRequest().get(`/api/company-values?tenantId=${tenantId}`);

export const getSubscriptionStatus = async (
  userId: string,
  tenantId: string
): Promise<AxiosResponse<SubscriptionStatus>> =>
  axiosBotRequest().get(
    `/api/subscription-check?userId=${userId}&tenantId=${tenantId}`
  );
export const getActivityId = async (
  id: string
): Promise<AxiosResponse<PeerToPeerIssuance>> =>
  axiosBotRequest().get(`/api/peer-to-peer-issuance?id=${id}`);

export const submitRecognition = async (
  payload: CreateNomination | CreateVote
) => axiosBotRequest().post("/api/recognise", payload);

export const getTeamsBotCredential = async (
  tenantId: string
): Promise<AxiosResponse<TeamsBotCredentialUpdateResponse>> =>
  axiosBotRequest().get(`/api/teams-bot-credential?tenantId=${tenantId}`);
