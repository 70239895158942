const config = {
  initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL,
  clientId: process.env.REACT_APP_CLIENT_ID,
  apiEndpoint: process.env.REACT_APP_FUNC_ENDPOINT,
  apiName: process.env.REACT_APP_FUNC_NAME,
  botApiEndpoint: process.env.REACT_APP_BOT_API_ENDPOINT,
  zuzoApiEndpoint: process.env.REACT_APP_ZUZO_API_ENDPOINT,
  zuzoToken: process.env.REACT_APP_ZUZO_API_TOKEN,
};

export default config;
