import "./CreditsBalance.css";

const CreditsBalance = () => {
  return (
    <div className="credit-container">
      {/* #TODO check for user's balance */}
      {/* <h3>
        Your Zuzo Balance: <span className="mint"> R120.00</span>
      </h3> */}
      <p>
        To access your Zuzo card, view your available balance, and start
        spending,{" "}
        <a
          href="https://orfc2.app.link/zuzo-download"
          rel="noreferrer"
          target="_blank"
          className="mint"
        >
          download the Zuzo mobile app.
        </a>
      </p>
    </div>
  );
};

export default CreditsBalance;
